<template>
  <div
    v-on-click-outside="hide"
    class="relative">
    <fade-transition>
      <div
        v-if="showOverlay && showing"
        class="popover-overlay fixed pin bg-ink opacity-50"
        @click="hide"></div>
    </fade-transition>
    <div
      v-if="$slots.target"
      class="popover-target"
      @click.prevent="clickTarget">
      <slot name="target"></slot>
    </div>
    <transition
      name="popover"
      @after-enter="onAfterEnter"
      @after-leave="onAfterLeave">
      <div
        v-show="showing"
        class="menu absolute"
        :style="menuPositionStyle"
        :class="[
          menuBorderClasses,
          menuBgClass,
          `${primaryOrigin}-${secondaryOrigin}`
        ]">
        <slot name="body"></slot>
        <transition name="secondary">
          <div
            v-if="$slots.secondary"
            class="secondary-menu absolute w-full shadow-2"
            :class="menuBgClass"
            :style="secondaryMenuStyle">
            <slot name="secondary"></slot>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { vOnClickOutside } from "@vueuse/components";
import FadeTransition from "@/app/javascript/components/transitions/FadeTransition.vue";

export default {
  name: "Popover",
  directives: {
    onClickOutside: vOnClickOutside
  },
  components: {
    FadeTransition
  },
  props: {
    origin: {
      type: String,
      default: "top left"
    },
    minWidth: {
      type: String,
      default: "200px"
    },
    alwaysShow: {
      type: Boolean,
      default: false
    },
    showOverlay: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    menuZIndex: {
      type: Number,
      default: 2
    },
    menuBgClass: {
      type: String,
      default: "bg-paper-1"
    }
  },
  emits: ["opened", "closed"],
  data() {
    return {
      showing: false
    };
  },
  computed: {
    primaryOrigin() {
      return this.origin.split(/\s+/)[0];
    },
    secondaryOrigin() {
      return this.origin.split(/\s+/)[1];
    },
    menuBorderClasses() {
      if (this.bordered) {
        return "border border-paper";
      }
      return null;
    },
    menuPositionStyle() {
      const adjustments = {};
      if (this.secondaryOrigin === "center") {
        adjustments.left = "50%";
        adjustments.transform = "translateX(-50%)";
      } else {
        adjustments[this.secondaryOrigin] = "0";
      }

      return {
        [this.primaryOrigin]: "100%",
        [`margin-${this.primaryOrigin}`]: "0.25rem",
        transformOrigin: this.origin,
        minWidth: this.minWidth,
        zIndex: this.menuZIndex,
        ...adjustments
      };
    },
    secondaryMenuStyle() {
      const secondaryMenuOrigin =
        this.primaryOrigin === "top" ? "top" : "bottom";

      return {
        [secondaryMenuOrigin]: "100%",
        [`margin-${secondaryMenuOrigin}`]: "0.25rem",
        transformOrigin: this.origin,
        minWidth: this.minWidth
      };
    }
  },
  mounted() {
    this.showing = this.alwaysShow;
  },
  methods: {
    clickTarget() {
      if (!this.disabled) {
        this.showing = !this.showing;
      }
    },
    hide() {
      if (!this.alwaysShow) {
        this.showing = false;
      }
    },
    onAfterEnter() {
      this.$emit("opened");
    },
    onAfterLeave() {
      this.$emit("closed");
    }
  }
};
</script>

<style lang="scss" scoped>
.popover-enter-from,
.popover-leave-to,
.secondary-enter-from,
.secondary-leave-to {
  opacity: 0;
  transform: scale(0.9);

  // Special case for centred popover
  &.top-center,
  &.bottom-center {
    transform: translate(-50%) scale(0.9) !important;
  }
}

.popover-enter-active,
.popover-leave-active,
.secondary-enter-active,
.secondary-leave-active {
  transition: all 0.2s ease-out;
}

.popover-enter-to,
.popover-leave,
.secondary-enter-to,
.secondary-leave {
  opacity: 1;
  transform: scale(1);

  // Special case for centred popover
  &.top-center,
  &.bottom-center {
    transform: translate(-50%) scale(1) !important;
  }
}

.popover-overlay {
  z-index: 1;
}

.popover-target {
  position: relative;
  z-index: 2;
}

.arrow {
  height: 16px;
  width: 16px;
}
</style>
